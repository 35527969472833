import React, { Component } from 'react';
import moment from "moment";

import {
    Grid, HardDrive, Book
} from "react-feather"

import colors from "../../utils/colors";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Link } from "react-router-dom";

import utils from "../../utils";

import api from "../../api/Result";
import AddToHomescreen from 'react-add-to-homescreen';
import { Helmet } from "react-helmet";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var Carousel = require('react-responsive-carousel').Carousel;

const menuCardData = [
    {
        productId: 7,
        lottery: "kienthiet",
        name: 'Hàng ngày',
        color: colors.facebookColor,
        title: 'Kiến thiết',
        title_2: 'Miền Bắc',
        title_3_1: '4.3 Tỷ Đồng'
    },
    {
        productId: 7,
        lottery: "235",
        slug: "2so",
        title: 'Lô tô',
        title_2: '2 số',
        title_3: 'Giá trị tối đa ',
        title_3_1: 'x71 lần',
        name: 'Hàng ngày - Lô tô 2,3,5 số',
        color: colors.facebookColor
    },
    {
        productId: 7,
        lottery: "235",
        title: 'Lô tô',
        title_2: '3 số',
        slug: "3so",
        title_3: 'Giá trị tối đa ',
        title_3_1: 'x445 lần',
        name: 'Hàng ngày - Lô tô 2,3,5 số',
        color: colors.facebookColor
    },
    {
        productId: 7,
        lottery: "235",
        title: 'Lô tô',
        slug: "5so",
        title_2: '5 số',
        title_3: 'Giá trị tối đa ',
        title_3_1: 'x20.000 lần',
        name: 'Hàng ngày - Lô tô 2,3,5 số',
        color: colors.facebookColor
    },

    {
        productId: 8,
        lottery: '234',
        title: 'Lô tô',
        title_2: '2 cặp số',
        slug: "2cap",
        title_3: 'Giá trị tối đa ',
        title_3_1: 'x15 lần',
        name: 'Hàng ngày',
        color: colors.facebookColor,
    },


    {
        productId: 8,
        lottery: '234',
        title: 'Lô tô',
        title_2: '3 cặp số',
        slug: "3cap",
        title_3: 'Giá trị tối đa ',
        title_3_1: 'x60 lần',
        name: 'Thứ tư, thứ bảy hàng tuần',
        color: colors.facebookColor,
    },
    {
        productId: 8,
        lottery: '234',
        title: 'Lô tô',
        title_2: '4 cặp số',
        slug: "4cap",
        title_3: 'Giá trị tối đa ',
        title_3_1: 'x1000 lần',
        name: 'Hàng ngày',
        color: colors.facebookColor,
    },
    {
        productId: 9,
        lottery: '6x36',
        title: 'ĐIỆN TOÁN',
        title_2: '6x36',
        title_3: 'Giá trị tối đa ',
        title_3_1: '6 tỷ đồng',
        name: 'Thứ tư, thứ bảy hàng tuần',
        color: colors.facebookColor,
    },
    {
        productId: 10,
        lottery: 'than-tai',
        title: 'ĐIỆN TOÁN',
        title_2: 'Thần Tài',
        title_3: 'Giá trị tối đa ',
        title_3_1: 'x1220 lần',
        name: 'Hàng ngày',
        color: colors.facebookColor,
    },
    {
        productId: 11,
        lottery: '123',
        title: 'ĐIỆN TOÁN',
        title_2: '123',
        title_3: 'Giá trị tối đa ',
        title_3_1: 'x40000 lần',
        name: 'Hàng ngày',
        color: colors.facebookColor,
    },

];


const logo = {
    '235': require("../../assets/images/logo/dien-toan.png")
};

class MenuCard extends React.Component {
    constructor(props) {
        super(props);
        this.itv = null;
        this.countDownDate = new Date(utils.generateCountDownDate(6)).getTime();
        this.state = {
            date: moment().format('DD/MM/YYYY HH:MM:SS'),
            time: '00:00:00',
            nextDate: '01/01/2020',
            nextDateTime: '01/01/2020 18:00:00',
        };
    }

    timeGenerate() {
        return this.state.time;
    }

    handleRefresh() {
        api.getNearestTerm(this.props.productId, (err, result) => {
            if (err) {

            } else {
                const that = this;
                if (this.itv) {
                    clearInterval(this.itv);
                }
                if (this.props.productId !== 6) {
                    let nextDate = result.date;
                    nextDate = moment(nextDate, 'DD/MM/YYYY HH:mm:ss');
                    let a = that.props.productId
                    this.setState({
                        nextDateTime: nextDate.format('DD/MM/YYYY HH:MM:SS'),
                        nextDate: nextDate.format('DD/MM/YYYY'),
                    });
                    that.itv = setInterval(function () {
                        let countDown = (nextDate - moment()) / 1000;
                        let day = Math.floor(countDown / 60 / 60 / 24);
                        let hour = 0;
                        let minute = 0;
                        let second = 0;
                        hour = Math.floor((countDown - day * 24 * 60 * 60) / 60 / 60);
                        minute = Math.floor((countDown - day * 24 * 60 * 60 - hour * 60 * 60) / 60);
                        second = Math.floor((countDown - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60));
                        if ((day === 0 && hour === 0 && minute === 0 && second === 0) || day < 0) {
                            clearInterval(that.itv);
                            that.handleRefresh();
                            return;
                        }
                        const str = `${a == 9 ? utils.formatValue(day) + ':' : ''}${utils.formatValue(hour)}:${utils.formatValue(minute)}:${utils.formatValue(second)}`;
                        that.setState({ time: str });
                    }, 1000);
                } else {
                    that.itv = setInterval(function () {
                        let now = new Date().getTime();
                        let distance = that.countDownDate - now;
                        if (distance < 0) {
                            that.countDownDate = new Date(utils.generateCountDownDate(6)).getTime();
                            return;
                        }

                        let day = Math.floor(distance / (1000 * 60 * 60 * 24));
                        let hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        let minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        let second = Math.floor((distance % (1000 * 60)) / 1000);

                        let str = `${utils.formatValue(day)}:${utils.formatValue(hour)}:${utils.formatValue(minute)}:${utils.formatValue(second)}`;
                        that.setState({ time: str });
                    }, 1000);

                }
            }
        });

    }

    componentDidMount() {
        this.handleRefresh();
    }

    componentWillUnmount() {
        clearInterval(this.itv);
    }
    isHot() {
        if (this.props.productId === 7)
            return true;
        return false;
    }
    isToday() {
        if (this.props.productId === 6) {
            return false;
        }

        const dt = moment(this.state.nextDate, 'DD/MM/YYYY');
        const today = moment();
        if (dt.get('days') === today.get('days') && dt.get('months') === today.get('months')) {
            return true;
        }
        return false;
    }
    render() {
        return (
            <Link to={this.props.productId < 13 ? `/${this.props.lottery}#${this.props.slug ?? ''}` : '#'} className='box-w-btn link-unset' >
                <div className='box-soxo-btn'>

                    <div className='time-btn'>
                        <span className='fnt-size-13-or'>{this.props.productId != 9 ? 'Hằng ngày' : 'Thứ 4, 7'}</span>
                        <div className='box-or-time'>{this.props.productId > 11 ? "00:00:00" : this.state.time}</div>
                    </div>

                    <div className='center-btn'>
                        <img src={require('../../assets/images/logo/dien-toan.png')} className='img-logo' alt="icon" style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }} />
                        <div className='fnt-size-13' style={{ flex: 1 }}>
                            <div>
                                {this.props.title}
                                <div className='fnt-size-18'>
                                    {this.props.title_2}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='value-btn'>
                        <span className='pd-10'>
                            {this.props.title_3}
                            <span style={{ fontWeight: 'bold' }}>{this.props.title_3_1}</span>
                        </span>

                    </div>
                </div>

            </Link>
        )

    }
}

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: {
                "mega645": "--.---.---.---- ₫",
                "power655": "---.---.---.--- ₫",
                "max3d": "x100 lần",
                "max3d_plus": "x100.000 lần",
                "max4d": "x1500 lần",
                "keno": "Keno - 2 tỷ vn₫",
                "235": "Xổ số Miền Bắc",
                "kenobao": "Keno Bao",
                "keno-chanle": "CHẴN LẺ LỚN NHỎ"
            },
            seo: {}
        }
    }


    async componentDidMount() {
        api.getAllResult((err, result) => {
            if (result) {
                let content = JSON.parse(JSON.stringify(this.state.content));
                content = {
                    ...content,
                    ...result
                };
                this.setState({ content })
            }
        })
        var seo = await utils.fetchSeo('home.json');
        this.setState({ seo: seo });
    }
    handleAddToHomescreenClick = () => {
        alert(`
        1. Open Share menu
        2. Tap on "Add to Home Screen" button`);
    }
    navigateDownload = () => {

        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        window.location.href = '/app/';

        return;
    }
    render() {
        return (
            <div className="home-page">

                <div style={{ height: "100%", overflowY: "auto" }}>
                    <Carousel
                        showArrows={true}
                        showIndicators={true}
                        showThumbs={false}
                        showStatus={false}
                        autoPlay={true}
                        infiniteLoop={true}
                        swipeable={true}
                    >
                        {
                            Array(1).fill(1).map((d, i) => (
                                <div key={i}>
                                    <img src='/banner3.jpg' alt="VIETLOTT-BANNER" />
                                </div>
                            ))
                        }
                    </Carousel>

                    <div style={{ marginTop: 10 }}>
                        <div className='row-btn'>
                            <a href={"/recharge"} className='row-btn-money'><span className='row-btn-text'>NẠP TIỀN</span></a>
                            <a href={'/cart'} className='row-btn-money' ><span className='row-btn-text'>GIỎ HÀNG</span></a>
                            <a href={'/withdrawal'} className='row-btn-money'><span className='row-btn-text'>RÚT TIỀN</span></a>
                        </div>
                    </div>

                    <div className='container-soxo-btn'>
                        <a href='/vietlott' className='box-w-btn' style={{ width: '50%' }}>
                            <div className='box-soxo-btn' style={{ borderRadius: 10, borderWidth: 1, borderColor: '#edecf1', backgroundColor: '#fff', position: 'relative', padding: 10, height: 120, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', }}>

                                <img
                                    style={{ width: '100%', height: '100%' }}
                                    src={require('../../assets/vl.jpg')}
                                />


                            </div>
                        </a>
                        {
                            menuCardData.map(e => (
                                <MenuCard
                                    slug={e.slug}
                                    title={e.title}
                                    lottery={e.lottery}
                                    productId={e.productId}
                                    loto={e.loto}
                                    title_2={e.title_2}
                                    title_3={e.title_3}
                                    title_3_1={e.title_3_1}
                                    content={this.state.content[e.lottery]}
                                />
                            ))
                        }
                        {/** */} <div style={{ padding: 5 }}>
                            <strong>Thông tin về nền tảng Xổ số Online - Đặt mua Vietlott - XSMB online</strong>
                            <p>Mua xổ số online đang là xu hướng thời đại mới 4.0. Vé sẽ được in và ghi xác minh thông tin CCCD/CMT của Quý khách, nên trúng các giải thưởng lớn chỉ Quý khách mới nhận được nên hoàn toàn yên tâm và tin tưởng!</p>
                            <strong>Mua xổ số online đang là xu hướng thời đại mới 4.0. Vé sẽ được in và ghi xác minh thông tin CCCD/CMT của Quý khách, nên trúng các giải thưởng lớn chỉ Quý khách mới nhận được nên hoàn toàn yên tâm và tin tưởng!</strong>
                            <p>5 bước đơn giản để trúng thưởng qua dịch vụ mua hộ Vietlott – Xổ Số Miền Bắc online của hệ thống iLOTO</p>
                            <p>1. Tải app ” iloto – vietlott “ trên <a href={
                                "https://play.google.com/store/apps/details?id=com.iloto"
                            }
                                target="_blank">CH play</a> hoặc <a href={
                                    "https://apps.apple.com/vn/app/iloto/id1632846722?l=vi"
                                }
                                    target="_blank">App Store</a> và đăng ký tài khoản bằng số điện thoại của bạn</p>
                            <p> 2. Nạp tiền vào tài khoản dự thưởng của hệ thống iLOTO</p>
                            <p>3. Tiến hành đặt vé: chọn bộ số yêu thích</p>
                            <p>4. Hệ thống App mua hộ Vietlott - XSMB sẽ thông báo khi có kết quả cho bạn</p>
                            <p>5. Rút tiền về từ tài khoản Trúng thưởng</p>
                            <p>Các sản phẩm: Xổ số Keno, <a href='/mega645'>Mega 6/45</a>, <a href='/power655'>Power 6/55</a>, <a href='/max3d'>Max3D</a>, <a href='/max3d_plus'>Max3D+</a> và <a href='/max3d_pro'>Max3D Pro</a>, <a href='/235'>Lô tô 2 3 5 số</a>, <a href='/234'>Lô tô 2 3 4 cặp số</a>, <a href='/than-tai'>Thần Tài</a>, <a href='/123'>Điện toán 1-2-3</a>, <a href='/6x36'>Điện toán 6/36..</a></p>
                            <p>Tiện lợi - Văn minh là các tiêu chí mà iLOTO mang đến cho khách hàng</p>
                            <strong>Nền tảng mua hộ Vietlott – XSMB Online iLOTO phát triển bởi:</strong>
                            <p>- Tên đơn vị: <strong>CÔNG TY TNHH DỊCH VỤ ILOTO</strong></p>
                            <p>- Địa chỉ: 148 Lương Định Của - Phường Kim Liên - Quận Đống Đa - Hà Nội</p>
                            <p>- Điện thoại: 0835.556.556</p>
                            <p>- Email: contacts.iloto@gmail.com</p>
                            <p>- Số giấy chứng nhận đăng ký kinh doanh: 0110125910 do Sở KH & ĐT Thành phố Hà Nội cấp</p>
                            <p>- Giờ làm việc: Từ 06h00 đến 22h00 tất cả các ngày trong tuần (kể cả chủ nhật, ngày lễ tết)</p>
                        </div>
                    </div>

                </div>

                {/* <div style={{flexDirection:'row',display:'flex',flexGrow:1,width:370}}>
                     <a href='/' style={{backgroundColor:'#fff',flex:1, borderRadius:10, overflow:'hidden',margin:10,justifyContent:'center',display:'flex'}}>
                     <img  style={{width:'70%'}} src={require('../../assets/images/logo/vietlot.jpeg')}/>
                     </a>
                     <a href='/xsmb' style={{backgroundColor:'#fff',flex:1, borderRadius:10, overflow:'hidden',margin:10,justifyContent:'center',display:'flex'}}>
                     <img  style={{width:'70%'}} src={require('../../assets/images/logo/dientoan.jpg')}/>
                     </a>
                 </div>      */}
                <div className="home-page__footer py-2 d-flex bgNav">
                    <div className="w-50 text-center">
                        <Link to={"/"} className="link-unset">
                            <div>
                                <Grid color={window.location.pathname === "/" ? "#fff" : "#eacad3"} />
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/" ? "#fff" : "#eacad3"
                                    }}
                                >Đặt vé</span>
                            </div>
                        </Link>
                    </div>
                    <div className="w-50 text-center">
                        <a href="/huong-dan" className="link-unset" target={'_blank'}>
                            <div>
                                <Book color={window.location.pathname === "/huong-dan" ? "#fff" : "#eacad3"} />
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/huong-dan" ? "#fff" : "#eacad3"
                                    }}
                                >Hướng dẫn</span>
                            </div>
                        </a>
                    </div>
                    {/* <div className="w-50 text-center">
                        <Link to={"/truc-tiep-keno"} className="link-unset">
                            <div>
                            <div class="livenow">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>                  
                            </div>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/truc-tiep-keno" ? "#fff" : "#eacad3"
                                    }}
                                >Trực tiếp keno</span>
                            </div>
                        </Link>
                    </div> */}
                    <div className="w-50 text-center">
                        <Link to={"/result"} className="link-unset">
                            <div>
                                <HardDrive color={window.location.pathname === "/result" ? "#fff" : "#eacad3"} />
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/result" ? "#fff" : "#eacad3"
                                    }}
                                >Kết quả</span>
                            </div>
                        </Link>
                    </div>



                </div>



                {/* <AddToHomescreen onAddToHomescreenClick={this.handleAddToHomescreenClick} /> */}
                <div className='btn-download' onClick={() => this.navigateDownload()}>

                    <FontAwesomeIcon
                        icon={faDownload}
                        style={{ marginRight: 2 }}

                    />
                    <p style={{ fontSize: 10, margin: 0 }} >Tải app</p>
                </div>
            </div>
        );
    }
}

export default Home;
