import React, { Component } from 'react';

import {
    Row, Col
} from "reactstrap";

import utils from "../../utils";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faTrophy, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { Link } from "react-router-dom";

const getProductOptionIdName = require('../../utils/productData').getProductOptionIdName;

const lottery_logo = {
    'mega645': require('../../assets/images/logo/mega645.png'),
    'power655': require('../../assets/images/logo/power655.png'),
    'max4d': require('../../assets/images/logo/max4d.png'),
    'max3d': require('../../assets/images/logo/max3d.png'),
    'max3d_plus': require('../../assets/images/logo/max3d_plus.png'),
    'keno': require('../../assets/images/logo/keno.png'),
    'dientoan': require('../../assets/images/logo/dien-toan.png'),
    max3d_pro: require('../../assets/images/logo/logomax3dpro.png'),
};

let lottery_id = ['', 'mega645', 'power655', 'max4d', 'max3d', 'max3d_plus', 'keno', 'dientoan', 'dientoan', 'dientoan', 'dientoan', 'dientoan', "max3d_pro", 'dientoan'];

class CartItem extends Component {

    generateImageTicket() {
        if (this.props.renderImageTicket) {
            return (
                <Row className="border-top mt-2 p-2 w-100">
                    <Col className="text-center">
                        <img
                            src={utils.safeUrlImage(this.props.data.imageFront)}
                            style={{ width: "90%" }}
                            alt="Mặt trước"
                            onClick={() => this.props.onPressImage({
                                title: "Mặt trước",
                                image: utils.safeUrlImage(this.props.data.imageFront)
                            })}
                        />
                    </Col>
                    <Col className="text-center">
                        <img
                            src={utils.safeUrlImage(this.props.data.imageBack)}
                            style={{ width: "90%" }}
                            alt="Mặt sau"
                            onClick={() => this.props.onPressImage({
                                title: "Mặt sau",
                                image: utils.safeUrlImage(this.props.data.imageBack)
                            })}
                        />
                    </Col>
                </Row>
            )
        }
    }

    generateHeader() {
        return (
            <Row className="cart-item__header">
                <Col>
                    <Row className="justify-content-center">
                        <img
                            src={lottery_logo[lottery_id[this.props.data.productId]]}
                            alt="icon"
                        />
                    </Row>
                    <Row className="justify-content-center">
                        <span>{getProductOptionIdName(this.props.data.productId, this.props.data.productOptionId).toUpperCase()}</span>
                    </Row>
                </Col>
            </Row>
        );
    }

    generateContent() {
        let result = this.props.result ? this.props.result.result : [];
        const productId = this.props.data.productId;
        return (
            <Row className="cart-item__content">
                <Col>
                    {
                        this.props.data.value.map(({ number, price }, index) => (
                            <Row key={index} className="my-1 font-weight-bold">
                                <div style={{ width: "10%" }} className="d-flex align-items-center">
                                    <span className="text-danger">{String.fromCharCode(index + 65)}</span>
                                </div>
                                <div
                                    style={{ width: ![1, 2].includes(this.props.data.productId) ? '70%' : '90%', }}
                                    className="d-flex flex-wrap"
                                >
                                    {this.props.data.productId == 13 ?
                                        <div style={{ marginLeft: 5, marginRight: 5 }}>
                                            <span


                                            >{number} ({this.props.data.value[index].numberOfTicket} vé)</span>
                                        </div>
                                        :
                                        this.props.data.productOptionId === 82 ?
                                            number.map((l, i) => (
                                                <span
                                                    key={'text-' + i.toString()}
                                                    className="mx-1"
                                                >
                                                    {['', 'Lẻ', 'Chẵn', 'Nhỏ', 'Lớn', 'Hòa Chẵn-Lẻ', 'Hòa Lớn-Nhỏ', 'Chẵn 11-12', 'Lẻ 11-12'][l]}
                                                </span>
                                            ))
                                            :
                                            number.map((l, i) => (
                                                <span
                                                    key={'text-' + i.toString()}
                                                    className="mx-1"
                                                    style={result.includes(l) ? { color: "red" } : {}}
                                                >
                                                    {l}
                                                </span>
                                            ))
                                    }
                                </div>
                                {
                                    ![1, 2].includes(this.props.data.productId) &&
                                    <div style={{ width: '20%' }} className="d-flex justify-content-center">
                                        <span>{price}K</span>
                                    </div>
                                }
                            </Row>
                        ))
                    }
                    <Col style={{ fontSize: "0.875rem" }}>
                        {
                            !this.props.onSelectTerm ?
                                this.props.data.termArrayWithDate.map((termArrayWithDate, index) => (
                                    <div key={`term-${index}`}>



                                        {productId >= 7 ?
                                            null : <span>
                                                <span>Kỳ:</span>
                                                <span>#{termArrayWithDate.termValue}</span>
                                                <span> - </span>
                                            </span>
                                        }

                                        <span>Ngày:</span>
                                        <span>#{termArrayWithDate.date}</span>
                                    </div>
                                ))
                                :
                                this.props.data.termArrayWithDate.map((termArrayWithDate, index) => (
                                    // <TouchableOpacity key={`term-${index}`} onPress={() => this.props.onSelectTerm(termArrayWithDate.termValue)}>
                                    <div onClick={() => {
                                        let returnValue = productId >= 7 ? termArrayWithDate.date : termArrayWithDate.termValue
                                        this.props.onSelectTerm(returnValue)
                                    }}>
                                        {productId >= 7 ?
                                            null : <span>
                                                <span>Kỳ:</span>
                                                <span>#{termArrayWithDate.termValue}</span>
                                                <span> - </span>
                                            </span>
                                        }
                                        <span>Ngày:</span>
                                        <span>#{termArrayWithDate.date}</span>
                                        {
                                            this.props.termSelected === termArrayWithDate.termValue &&
                                            <FontAwesomeIcon icon={faArrowLeft} className="ml-1" color="red" />
                                        }
                                    </div>
                                    // </TouchableOpacity>
                                ))

                        }
                    </Col>
                </Col>
            </Row>
        );
    }

    generateFooter() {
        return (
            <Row className="border-top pt-2 mt-2 font-weight-bold">
                <Col>
                    <Row>
                        <Col>
                            <span>$</span>
                            &nbsp;
                            <span className="text-danger">{(this.props.data.totalPrice * 1000).getMoneyFormat()} ₫</span>
                        </Col>
                        <Col className="text-right">
                            {
                                !this.props.noTrash &&
                                <div onClick={this.props.onRemove} className="hover-pointer">
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                    />&nbsp;
                                    <span>Hủy vé</span>
                                </div>
                            }
                            {
                                this.props.renderImageTicket &&
                                <Link
                                    // style={styles.footer.icon.container}
                                    // onPress={() => this.props.navigation.navigate('CompareResult', this.props.data)}
                                    className="hover-pointer link-unset"
                                    to={`/compare-result?id=${this.props.data.id}`}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrophy}
                                        color={'red'}
                                    />
                                    <span>&nbsp;So kết quả</span>
                                </Link>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Row className="cart-item my-3 w-100">
                <Col>
                    {
                        this.generateHeader()
                    }
                    {
                        this.generateContent()
                    }
                    {
                        this.generateImageTicket()
                    }
                    {
                        this.generateFooter()
                    }
                </Col>
            </Row>
        );
    }
}

export default CartItem;
